import { lowerHiragana, lowerKatakana, lowerToUpperHankakuMap } from '../text-code'
import Task from './task'

export default class ToUpperCase implements Task {
  private text = ''

  public execute (text: string): string {
    this.text = text
    this.convertAlpha().convertHiragana().convertKatakana().convertHankakuKana()
    return this.text
  }

  private convertAlpha (): ToUpperCase {
    this.text = this.text.replace(/[a-z]/g, (s: string): string => {
      return String.fromCharCode(s.charCodeAt(0) - 32)
    })

    return this
  }

  private convertHiragana (): ToUpperCase {
    this.text = this.text.replace(lowerHiragana, (s: string): string => {
      return String.fromCharCode(s.charCodeAt(0) + 1)
    })

    return this
  }

  private convertKatakana (): ToUpperCase {
    this.text = this.text.replace(lowerKatakana, (s: string): string => {
      return String.fromCharCode(s.charCodeAt(0) + 1)
    })

    return this
  }

  private convertHankakuKana (): ToUpperCase {
    for (let i = 0; i < this.text.length; i++) {
      const code = this.text.charCodeAt(i)

      if (code in lowerToUpperHankakuMap) {
        this.text = this.text.replace(new RegExp(this.text.charAt(i), 'g'), (): string => {
          return String.fromCharCode(lowerToUpperHankakuMap[code])
        })
      }
    }

    return this
  }
}
