
import { Component, Vue, namespace, Prop } from 'nuxt-property-decorator'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { RecaptchaVerifier, MultiFactorResolver, PhoneAuthProvider, PhoneMultiFactorGenerator, AuthErrorCodes } from 'firebase/auth'
import InputField from '~/components/form/input-field.vue'
import Logo from '~/components/element/logo.vue'
import Notifications from '~/components/layout/notifications.vue'
import FormTitle from '~/components/form/form-title.vue'
import StatusIcon from '~/components/element/status.vue'
import { emitError, isFirebaseError } from '~/utils/nuxt-helper'
import { Msg } from '~/types/notifications'
import { codePattern } from '~/utils/validators'

const notificationStore = namespace('notifications')

@Component({
  layout: 'single',
  mixins: [validationMixin],
  validations: {
    code: {
      required,
      pattern: codePattern,
    },
  },
  components: {
    InputField,
    Logo,
    Notifications,
    FormTitle,
    StatusIcon,
  },
  middleware: ['authenticated-signin'],
})
export default class InputCode extends Vue {
  @notificationStore.Mutation
  private readonly refreshMessages!: () => void

  @notificationStore.Mutation
  private readonly setForceReset!: (p: boolean) => void

  @notificationStore.Mutation
  private readonly setInstant!: (msg: Msg) => void

  @notificationStore.Mutation
  private readonly refreshInstants!: () => void

  @Prop({ required: true })
  private readonly verificationId!: string

  @Prop({ required: true })
  private readonly multiFactorResolver!: MultiFactorResolver | null

  private code = ''
  private loading = false
  private rv: RecaptchaVerifier | null = null

  get codeErrors (): string[] {
    const errors: string[] = []
    if (!this.$v.code.$dirty) {
      return errors
    }
    !this.$v.code.required && errors.push(this.$i18n.t('validation.required', { target: '認証コード' })?.toString())
    !this.$v.code.pattern && errors.push(this.$i18n.t('validation.pattern', { target: '認証コード' })?.toString())
    return errors
  }

  private async onSubmit (): Promise<void> {
    this.$v.$touch()
    if (this.$v.$invalid) {
      return
    }
    this.setForceReset(true)
    this.loading = true
    this.refreshInstants()
    await this.signin()
  }

  private retryCount = 0

  async signin (): Promise<void> {
    const cred = PhoneAuthProvider.credential(this.verificationId, this.code)
    const assertion = PhoneMultiFactorGenerator.assertion(cred)
    if (this.multiFactorResolver) {
      try {
        const userCredential = await this.multiFactorResolver.resolveSignIn(assertion)
        this.$emit('success', userCredential.user)
      } catch (e) {
        if (isFirebaseError(e)) {
          if (e.code === AuthErrorCodes.INVALID_CODE) {
            this.setInstant({
              type: 'error',
              message: this.$i18n.t('signin.twoFactor.error.wrong').toString(),
            })
            this.loading = false
            return
          }

          if (e.code === AuthErrorCodes.CODE_EXPIRED) {
            this.setInstant({
              type: 'error',
              message: this.$i18n.t('signin.twoFactor.error.expired').toString(),
            })
            this.loading = false
            this.expired()
            return
          }

          if (e.code === AuthErrorCodes.NETWORK_REQUEST_FAILED) {
            if (this.retryCount > 2) {
              this.setInstant({
                type: 'error',
                message: this.$i18n.t('signin.error.networkError').toString(),
              })
              return
            }
            this.retryCount++
            this.signin()
            return
          }

          if (e.code === AuthErrorCodes.INTERNAL_ERROR) {
            this.setInstant({
              type: 'error',
              message: this.$i18n.t('signin.error.internalError').toString(),
            })
            emitError(this.$nuxt, e, true)
            this.loading = false
            this.expired()
            return
          }
        }

        emitError(this.$nuxt, e)
      }
    } else {
      emitError(this.$nuxt, new Error('no multiFactorResolver'))
    }
  }

  private expired (): void {
    this.$emit('expired')
  }

  private onBack (): void {
    this.refreshInstants()
    this.$emit('back')
  }
}
