import {
  upperDakutenKatakana,
  upperDakutenKatakanaVu,
  upperHandakutenKatakana,
  upperToHankakuMap,
} from '../text-code'
import Task from './task'

// 大文字のカタカナを半角カナにする
export default class ToHalfKana implements Task {
  private text = ''

  public execute (text: string): string {
    this.text = text
    this.separateDakuten().separateHandakuten().convertKatakanaAndSpecials()
    return this.text
  }

  private convertKatakanaAndSpecials (): ToHalfKana {
    for (let i = 0; i < this.text.length; i++) {
      const code = this.text.charCodeAt(i)

      if (code in upperToHankakuMap) {
        this.text = this.text.replace(new RegExp(this.text.charAt(i), 'g'), (): string => {
          return String.fromCharCode(upperToHankakuMap[code])
        })
      }
    }
    return this
  }

  private separateDakuten (): ToHalfKana {
    this.text = this.text.replace(upperDakutenKatakana, (s): string => {
      return String.fromCharCode(s.charCodeAt(0) - 1) + '\u309B'
    })
    this.text = this.text.replace(upperDakutenKatakanaVu, '\u30A6\u309B')
    return this
  }

  private separateHandakuten (): ToHalfKana {
    this.text = this.text.replace(upperHandakutenKatakana, (s): string => {
      return String.fromCharCode(s.charCodeAt(0) - 2) + '\u309C'
    })
    return this
  }
}
