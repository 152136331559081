import Vue from 'vue'
import { helpers, ValidationRule } from 'vuelidate/lib/validators'
import { subDays, isBefore, parseISO } from 'date-fns'
import { Big } from 'big.js'
import TextConverter from '~/utils/text-converter'

const zipCode = helpers.regex('zipCode', /^\d{3}-?\d{4}$/)
const tel = helpers.regex('tel', /^((0(\d{1}-?\d{4}|\d{2}-?\d{3}|\d{3}-?\d{2}|\d{4}-?\d{1}|[5789]0-?\d{4})-?\d{4})|(0120-?\d{3}-?\d{3}))$/)
const corporateNumber = helpers.regex('corporateNumber', /^\d{13}$/)
const bankAccountNumber = helpers.regex('bankAccountNumber', /^\d{7}$/)
const holderName = helpers.regex('holderName', /^[0-9a-zA-Z\uFF66-\uFF6F\uFF71-\uFF9D().\u002D\u002F\uFF9E\uFF9F\u0020\u3041\u3043\u3045\u3047\u3049\u3063\u3083\u3085\u3087\u308E\u30A1\u30A3\u30A5\u30A7\u30A9\u30C3\u30E3\u30E5\u30E7\u30EE\u30F5\u30F6\uFF67-\uFF6F\u3041-\u3093\u30A1-\u30F6\uFF21-\uFF3A\uFF41-\uFF5A\uFF10-\uFF19\uFF08\uFF09\u3000\u30FC]*$/)
const codePattern = helpers.regex('codePattern', /^[0-9]{6}$/)
const invoiceNumberPattern = helpers.regex('invoiceNumberPattern', /^T[0-9]{13}$/)

const decimalPoint = (v: string): boolean => {
  if (!v) {
    return true
  }
  try {
    const t = new Big(v)
    return t.mul(10000).toString().indexOf('.') === -1
  } catch (e) {
    console.log(e)
    return true
  }
}
const upperCaseLetter = helpers.regex('upperCaseLetter', /[A-Z]/)
const lowerCaseLetter = helpers.regex('lowerCaseLetter', /[a-z]/)
const symbolOrNumeric = helpers.regex('symbolOrNumeric', /\d|[!\x22#$%\x26\x5C'()*+,-./:;<=>?@\x5B\\\x5D^_`{|}~]/)

const maxFileSize = function (max: number): (value: File) => boolean {
  const v = function (value: File): boolean {
    if (!value) {
      return true
    }
    const size = new Big(value.size).div(1024)
    return size.lte(new Big(max))
  }

  return helpers.withParams({ type: 'maxFileSize', max }, function (value: File): boolean {
    return v(value)
  })
}

const imageMime = function (value: File): boolean {
  if (!value) {
    return true
  }

  switch (value.type) {
    case 'image/jpeg':
    case 'image/png':
    case 'image/gif':
      return true
    default:
      return false
  }
}

const greaterEqual = (gte: string): ValidationRule => {
  return helpers.withParams({ type: 'greaterEqual', gte }, function (this: Vue, value: string, parentVm: Vue) {
    if (!value) {
      return true
    }

    const v = new Date(value)
    const t = new Date(helpers.ref(gte, this, parentVm))
    return v >= t
  })
}

const lessEqual = (lte: string): ValidationRule => {
  return helpers.withParams({ type: 'lessEqual', lte }, function (this: Vue, value: string, parentVm: Vue) {
    if (!value) {
      return true
    }
    let t = parseISO(lte)
    if (Number.isNaN(t.getTime())) {
      t = new Date(helpers.ref(lte, this, parentVm))
    }
    const v = new Date(value)
    return v <= t
  })
}

const maxLengthAfterConverted = (max: number): ValidationRule => {
  return helpers.withParams({ type: 'maxLengthAfterConverted', max }, function (this: Vue, value: string) {
    if (!value) {
      return true
    }

    const con = new TextConverter(value)
    const r = con.doubleByte().toKatakana().toUpperCase().toHalfKana().execute()
    return r.length <= max
  })
}

const minBeforeDate = (min: number): ValidationRule => {
  return helpers.withParams({ type: 'minBeforeDate', min }, function (this: Vue, value: string) {
    if (!value) {
      return true
    }

    const v = new Date(value)
    const t = subDays(new Date(), min + 1)
    return isBefore(t, v)
  })
}

const beforeAndAfterSpace = (text: string): boolean => {
  if (!text) {
    return true
  }
  return text.trim() === text
}

export {
  zipCode,
  tel,
  corporateNumber,
  bankAccountNumber,
  holderName,
  decimalPoint,
  upperCaseLetter,
  lowerCaseLetter,
  symbolOrNumeric,
  maxFileSize,
  imageMime,
  greaterEqual,
  lessEqual,
  maxLengthAfterConverted,
  minBeforeDate,
  codePattern,
  invoiceNumberPattern,
  beforeAndAfterSpace,
}
