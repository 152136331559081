import DoubleByte from './text-converter/double-byte'
import HalfKanaToKatakana from './text-converter/half-to-kana'
import ToHalfKana from './text-converter/to-halfkana'
import ToKatakana from './text-converter/to-katakana'
import ToUpperCase from './text-converter/to-uppercase'

import Task from './text-converter/task'

export default class TextConverter {
  private text: string
  private tasks: Task[]

  constructor (text: string) {
    this.text = text
    this.tasks = []
  }

  public doubleByte (): TextConverter {
    this.tasks.push(new DoubleByte())
    return this
  }

  public toKatakana (): TextConverter {
    this.tasks.push(new ToKatakana())
    return this
  }

  public toUpperCase (): TextConverter {
    this.tasks.push(new ToUpperCase())
    return this
  }

  public toHalfKana (): TextConverter {
    this.tasks.push(new ToHalfKana())
    return this
  }

  public halfKanaToKatakana (): TextConverter {
    this.tasks.push(new HalfKanaToKatakana())
    return this
  }

  public execute (): string {
    this.tasks.forEach((task: Task) => {
      this.text = task.execute(this.text)
    })

    return this.text
  }
}
