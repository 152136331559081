import { upperDakutenKatakana, upperHandakutenKatakana, upperToHankakuMap } from '../text-code'
import Task from './task'

// 半角カナを大文字のカタカナに
export default class HalfKanaToKatakana implements Task {
  private text = ''

  public execute (text: string): string {
    this.text = text
    this.convertKatakana().joinDakuten().joinHanDakuten()
    return this.text
  }

  private convertKatakana (): HalfKanaToKatakana {
    for (let i = 0; i < this.text.length; i++) {
      const code = this.text.charCodeAt(i)

      const key = Object.keys(upperToHankakuMap).find((k): boolean => {
        const key = parseInt(k, 10)
        return upperToHankakuMap[key] === code
      })

      if (!key) {
        continue
      }
      const c = parseInt(key, 10)
      this.text = this.text.replace(new RegExp(this.text.charAt(i), 'g'), (): string => {
        return String.fromCharCode(c)
      })
    }
    return this
  }

  private joinDakuten (): HalfKanaToKatakana {
    // 半角濁点はサポート外 convertKatakanaしたあと前提の処理
    const dakutenCode = 0x309B
    const dakutenIndexes = []
    for (let i = 1; i < this.text.length; i++) {
      const code = this.text.charCodeAt(i)
      if (code !== dakutenCode) {
        continue
      }

      let katakana = this.text.charCodeAt(i - 1)
      katakana++
      const t = String.fromCharCode(katakana)
      if (!t.match(upperDakutenKatakana)) {
        continue
      }

      this.text = this.text.substr(0, i - 1) + t + this.text.substr(i)
      dakutenIndexes.push(i)
    }
    dakutenIndexes.forEach((di, i) => {
      const index = di - i
      this.text = this.text.substr(0, index) + this.text.substr(index + 1)
    })
    return this
  }

  private joinHanDakuten (): HalfKanaToKatakana {
    // 半角半濁点はサポート外 convertKatakanaしたあと前提の処理
    const hanDakutenCode = 0x309C
    const handakuIndexes = []
    for (let i = 1; i < this.text.length; i++) {
      const code = this.text.charCodeAt(i)
      if (code !== hanDakutenCode) {
        continue
      }

      let katakana = this.text.charCodeAt(i - 1)
      katakana += 2
      const t = String.fromCharCode(katakana)
      if (!t.match(upperHandakutenKatakana)) {
        continue
      }

      this.text = this.text.substr(0, i - 1) + t + this.text.substr(i)
      handakuIndexes.push(i)
    }

    handakuIndexes.forEach((hi, i) => {
      const index = hi - i
      this.text = this.text.substr(0, index) + this.text.substr(index + 1)
    })

    return this
  }
}
