
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { isHTMLInputObject } from '~/utils/vue-helper'

@Component({
  props: {
    type: {
      type: String,
      default: 'text',
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
    step: String,
    placeholder: String,
    loading: {
      type: Boolean,
      default: false,
    },
    suppress: {
      type: Boolean,
      required: false,
    },
    optional: {
      type: Boolean,
      default: false,
    },
  },
})
export default class extends Vue {
  @Prop({ required: true, type: String })
  private readonly value!: string

  @Prop({ type: Array, default: (): string[] => [] })
  private readonly errors!: string[]

  @Prop({ type: Array, default: (): string[] => [] })
  private readonly classes!: string[]

  @Prop({ type: Boolean, default: false })
  private readonly disabled!: boolean

  @Prop({ type: String, required: false })
  private readonly autocomplete?: string

  mounted (): void {
    if (this.autocomplete) {
      const input = this.$refs.input
      if (isHTMLInputObject(input)) {
        input.autocomplete = this.autocomplete
      }
    }
  }

  private input (e: Event): void {
    if (e.target === null) {
      return
    }

    if (!isHTMLInputObject(e.target)) {
      return
    }
    this.$emit('input', e.target.value, e)
  }

  private blur (e: Event): void {
    if (e.target === null) {
      return
    }

    if (!isHTMLInputObject(e.target)) {
      return
    }
    this.$emit('blur', e.target.value, e)
  }

  get additionalClasses (): string[] {
    const classes = [...this.classes]
    if (this.errors.length) {
      classes.push('border-warning bg-warning-light')
    }
    if (this.disabled) {
      classes.push('border-gray-2 bg-gray-2 pointer-events-none')
    }
    return classes
  }
}
