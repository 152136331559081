// ぁぃぅぇぉゃゅょゎ
export const lowerHiragana = /[\u3041\u3043\u3045\u3047\u3049\u3063\u3083\u3085\u3087\u308E]/g
// ァィゥェォャュョヮ
export const lowerKatakana = /[\u30A1\u30A3\u30A5\u30A7\u30A9\u30C3\u30E3\u30E5\u30E7\u30EE]/g
// ヵヶ
export const lowerKaKeKatakana = /[\u30F5\u30F6]/g
// ｧｨｩｪｫｬｭｮｯ
export const lowerHankakuKatakana = /[\uFF67\uFF68\uFF69\uFF6A\uFF6B\uFF6C\uFF6D\uFF6E\uFF6F]/g

export const lowerToUpperHankakuMap: {[key: number]: number} = {
  0xFF67: 0xFF71,
  0xFF68: 0xFF72,
  0xFF69: 0xFF73,
  0xFF6A: 0xFF74,
  0xFF6B: 0xFF75, // ｧｨｩｪｫ
  0xFF6C: 0xFF94,
  0xFF6D: 0xFF95,
  0xFF6E: 0xFF96,
  0xFF6F: 0xFF82, // ｬｭｮｯ
}

// tslint:disable-next-line:max-line-length
export const upperDakutenKatakana = /[\u30AC\u30AE\u30B0\u30B2\u30B4\u30B6\u30B8\u30BA\u30BC\u30BE\u30C0\u30C2\u30C5\u30C7\u30C9\u30D0\u30D3\u30D6\u30D9\u30DC]/g
export const upperDakutenKatakanaVu = /\u30F4/g
export const upperHandakutenKatakana = /[\u30D1\u30D4\u30D7\u30DA\u30DD]/g

// 30F4
export const upperToHankakuMap: {[key: number]: number} = {
  0x30A2: 0xFF71,
  0x30A4: 0xFF72,
  0x30A6: 0xFF73,
  0x30A8: 0xFF74,
  0x30AA: 0xFF75, // ア
  0x30AB: 0xFF76,
  0x30AD: 0xFF77,
  0x30AF: 0xFF78,
  0x30B1: 0xFF79,
  0x30B3: 0xFF7A, // カ
  0x30B5: 0xFF7B,
  0x30B7: 0xFF7C,
  0x30B9: 0xFF7D,
  0x30BB: 0xFF7E,
  0x30BD: 0xFF7F, // サ
  0x30BF: 0xFF80,
  0x30C1: 0xFF81,
  0x30C4: 0xFF82,
  0x30C6: 0xFF83,
  0x30C8: 0xFF84, // タ
  0x30CA: 0xFF85,
  0x30CB: 0xFF86,
  0x30CC: 0xFF87,
  0x30CD: 0xFF88,
  0x30CE: 0xFF89, // ナ
  0x30CF: 0xFF8A,
  0x30D2: 0xFF8B,
  0x30D5: 0xFF8C,
  0x30D8: 0xFF8D,
  0x30DB: 0xFF8E, // ハ
  0x30DE: 0xFF8F,
  0x30DF: 0xFF90,
  0x30E0: 0xFF91,
  0x30E1: 0xFF92,
  0x30E2: 0xFF93, // マ
  0x30E4: 0xFF94,
  0x30E6: 0xFF95,
  0x30E8: 0xFF96, // ヤ
  0x30E9: 0xFF97,
  0x30EA: 0xFF98,
  0x30EB: 0xFF99,
  0x30EC: 0xFF9A,
  0x30ED: 0xFF9B, // ラ
  0x30EF: 0xFF9C,
  0x30F2: 0xFF66,
  0x30F3: 0xFF9D, // ワヲン
  0x30FC: 0x002D, // ー
  0x3000: 0x0020, // スペース
  0x309B: 0xFF9E,
  0x309C: 0xFF9F, // 濁点、半濁点
}

export const unavailabledCharRegex = /[^0-9a-zA-Z\uFF66-\uFF6F\uFF71-\uFF9D().\u002D\u002F\uFF9E\uFF9F\u0020\u3041\u3043\u3045\u3047\u3049\u3063\u3083\u3085\u3087\u308E\u30A1\u30A3\u30A5\u30A7\u30A9\u30C3\u30E3\u30E5\u30E7\u30EE\u30F5\u30F6\uFF67-\uFF6F\u3041-\u3093\u30A1-\u30F6\uFF21-\uFF3A\uFF41-\uFF5A\uFF10-\uFF19\uFF08\uFF09\u3000\u30FC]/g
