
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  @Prop({ type: String, required: true })
  private readonly title!: string

  @Prop({ type: String, default: '' })
  private readonly description!: string

  @Prop({ type: String, required: true })
  private readonly id!: string

  @Prop({ type: Boolean, default: false })
  private readonly required!: boolean

  get hasDescription (): boolean {
    return this.description !== ''
  }
}
